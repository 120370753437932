import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import FormControl from 'react-bootstrap/FormControl';
import concat from 'lodash/concat';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';

import { FormContext } from './Form';
import { FormGroupContext } from './FormGroup';

function InputFn(fwdProps, ref) {
	const {
		as: asInput,
		children,
		className,
		disabled,
		onChange: propOnChange,
		type,
		validations,
		value,
		use,
		...props
	} = fwdProps;
	const formState = useContext(FormContext);
	const groupState = useContext(FormGroupContext);

	const disable = disabled || formState.submitting;
	const name = groupState.name || groupState.controlId || props.name;
	const values = concat(get(formState.form, name)).map(v => (isBoolean(v) ? String(v) : v));
	const isCheckbox = ['checkbox', 'radio', 'switch'].includes(type);
	const isRadio = ['radio'].includes(type);
	const id = groupState.controlId;
	const Input = asInput || (isCheckbox ? 'input' : FormControl);
	const error = groupState.error || formState.errors[name];
	const selected = isCheckbox && values.includes(value);

	useEffect(() => groupState.setSelected && groupState.setSelected(selected), [selected]);
	useEffect(() => {
		value && !isCheckbox && formState.setField(name, value);
	}, [value]);
	useEffect(() => formState.register(name, validations), [name, validations]);
	useEffect(() => () => formState.unregister(name), []);

	function onChange(event) {
		const target = event.target || { name, value: event };
		let value = target.value;
		if (isCheckbox && !isRadio) {
			const valueSet = new Set(values);
			if (target.checked) valueSet.add(value);
			if (!target.checked) valueSet.delete(value);
			value = Array.from(valueSet).filter(v => v != null);
		}

		formState.setField(target.name, value);
		formState.validate(target.name, value, validations);
		propOnChange && propOnChange(event);
	}

	const listeners = { ...props, [use]: onChange };
	if (Input === FormControl) listeners.onBlur = onChange;
	if (Input === FormControl) listeners.onInput = onChange;

	return (
		<Input
			disabled={disable}
			id={id}
			name={name}
			type={type}
			value={isCheckbox ? value : get(formState.form, name)}
			checked={isCheckbox ? values.includes(value) : undefined}
			{...listeners}
			className={classnames(className, {
				'is-invalid': formState.needsValidation && error,
			})}
			ref={ref}
		>
			{children}
		</Input>
	);
}

const Input = React.forwardRef(InputFn);

Input.defaultProps = {
	use: 'onChange',
};

export default Input;
