import { useState } from 'react';

function useToggle(initialState) {
	const [shown, setShown] = useState(initialState);

	return {
		hide: () => setShown(false),
		initialState,
		setShown,
		show: () => setShown(true),
		shown,
		toggle: () => setShown(shown => !shown),
	};
}

export default useToggle;
