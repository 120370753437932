import React, { useContext } from 'react';

export const DataProviderContext = React.createContext({});

function DataProvider({ children, data, ...props }) {
	const prevData = useContext(DataProviderContext);
	return (
		<DataProviderContext.Provider value={{ ...prevData, ...props, ...data }}>
			{children}
		</DataProviderContext.Provider>
	);
}

export default DataProvider;
