import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import BSForm from 'react-bootstrap/Form';
const Promise = require('bluebird');

import concat from 'lodash/concat';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';

export const FormContext = React.createContext({
	errors: {},
	form: {},
	needsValidation: false,
	register() {},
	setError() {},
	setErrors() {},
	setField() {},
	setForm() {},
	setSubmitting() {},
	submitting: false,
	unregister() {},
	validate() {},
});

function Form({
	as: FormElem,
	children,
	className,
	controlData,
	initialState,
	needsValidation,
	onBlur,
	onChange,
	onForm,
	onSubmit,
	prevent,
	validateOnSubmit,
	...props
}) {
	const history = useHistory();

	const [httpError, setHttpError] = useState(null);
	const [form, setForm] = useState({ needsValidation, ...initialState });
	const [validators, setValidators] = useState({});
	const [submitting, setSubmitting] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (controlData) setForm({ needsValidation, ...controlData });
	}, [controlData]);

	function setField(name, value) {
		setForm(state => {
			const newForm = { ...state };
			set(state, name, value);
			set(newForm, name, value);
			return newForm;
		});
	}

	function setError(name, error) {
		setErrors(errors => ({ ...errors, [name]: error }));
	}

	function register(name, validations) {
		if (validators[name] === validations) return;
		setValidators(val => ({ ...val, [name]: validations }));
	}
	function unregister(name) {
		setValidators(val => ({ ...val, [name]: null }));
	}

	function checkValid(name) {
		const value = form[name];
		if (!validators[name]) return null;
		const validator = validators[name].find(fn => fn(name, value, form));
		return validator && validator(name, value, form);
	}

	function validate(name, value, validations) {
		if (!name) {
			const errorMsgs = mapValues(validators, (v, n) => v && checkValid(n));
			setErrors(errorMsgs);
			return Promise.resolve(errorMsgs);
		}

		if (!value && !validations) {
			const errorMsg = checkValid(name);
			setErrors(errors => ({ ...errors, [name]: errorMsg }));
			return Promise.resolve(errorMsg);
		}

		let hasError;
		return Promise.each(concat(validations), fn => {
			if (!isFunction(fn)) return false;
			return Promise.all([fn(name, value, form)]).then(([message]) => {
				if (hasError) return;

				setError(name, message);
				hasError = message;
				return message;
			});
		}).then(() => hasError);
	}

	function eventSubmit(event) {
		setField('needsValidation', true);

		if (prevent) event.preventDefault();
		if (!validateOnSubmit) return isFunction(onSubmit) && onSubmit(event, formState);

		event.preventDefault();

		validate().then(validations => {
			const invalid = Object.values(validations).find(Boolean);

			if (invalid || !isFunction(onSubmit)) return false;

			onSubmit(event, formState, validations);
		});
		return false;
	}

	const formState = {
		errors,
		form,
		history,
		httpError,
		needsValidation: form.needsValidation,
		onSubmit: eventSubmit,
		register,
		setError,
		setErrors,
		setField,
		setForm,
		setHttpError,
		setSubmitting,
		submitting,
		unregister,
		validate,
	};

	useEffect(() => {
		if (onForm) onForm(formState);
	}, [form]);

	const eventWrapper = propEvent => isFunction(propEvent) && (event => propEvent(event, formState));
	const events = {
		onBlur: eventWrapper(onBlur),
		onChange: eventWrapper(onChange),
		onSubmit: eventSubmit,
	};

	return (
		<FormElem
			noValidate
			{...props}
			{...pickBy(events, isFunction)}
			className={classnames(className, { 'needs-validation': form.needsValidation })}
		>
			<FormContext.Provider value={formState}>{children}</FormContext.Provider>
		</FormElem>
	);
}

Form.defaultProps = {
	children: [],
	as: BSForm,
};

export function withForm(ExtComponent, extProps = {}) {
	return props => (
		<Form {...extProps} {...props}>
			<ExtComponent {...props} />
		</Form>
	);
}

export function FormCtx({ children }) {
	const form = useContext(FormContext);
	return children(form);
}

FormCtx.defaultProps = {
	children() {},
};

export default Form;
