import React, { Component } from 'react';
import isFunction from 'lodash/isFunction';
import ErrorPanel from './ErrorPanel';

export default class CatchError extends Component {
	state = {};

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error };
	}
	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });
	}

	render() {
		if (this.state.error) {
			if (isFunction(this.props.onError)) return this.props.onError(this.state);

			return <ErrorPanel {...this.props} {...this.state} />;
		}

		return this.props.children;
	}
}

export function withCatchError(ExtComponent, onError = ErrorPanel) {
	return ({ ...props }) => (
		<CatchError onError={onError}>
			<ExtComponent {...props} />
		</CatchError>
	);
}
