import React from 'react';
import Alert from 'react-bootstrap/Alert';
import get from 'lodash/get';

function ErrorPanel({ error, ...props }) {
	if (!error) return null;

	return (
		<Alert variant="warning" {...props}>
			<Alert.Heading>{get(error, 'message')}</Alert.Heading>
			<hr />
			<pre>{get(error, 'stack')}</pre>
		</Alert>
	);
}

export default ErrorPanel;
