import { isPromise } from 'rxjs/internal/util/isPromise';
import { isArrayLike } from 'rxjs/internal/util/isArrayLike';

/* eslint-disable-next-line camelcase */
import { observable as Symbol_observable } from 'rxjs/internal/symbol/observable';

export function isSubscribable(item) {
	if (!item) return false;

	return typeof item[Symbol_observable] === 'function' || isArrayLike(item) || isPromise(item);
}

export function util() {}
