import React, { useContext, Suspense } from 'react';
import concat from 'lodash/concat';
import invoke from 'lodash/invoke';

import CatchError from './CatchError';
import isEditor from '../utils/isEditor';
import ComponentInlet from './ComponentInlet';
import { ManifestContext } from './ComponentManifest';

export const ComponentOutletCtx = React.createContext({});

function ComponentOutlet({ fieldId, components, afterEach: AfterEach, ...passProps }) {
	const manifest = useContext(ManifestContext);
	const parent = useContext(ComponentOutletCtx);
	const cmps = concat(components).filter(Boolean);
	const editMode = isEditor();
	if (editMode) invoke(window, 'outsource-school.sync');

	return (
		<>
			{editMode && fieldId && <ComponentInlet fieldId={fieldId} position={0} />}
			{cmps.map((cmp, index) => {
				const { id, _uid, component, pageId = parent.pageId, _editor, ...props } = cmp;
				const ExtComponent = manifest[component] || 'null';
				const p = index + 1;

				const data = JSON.stringify({ _uid, id: _uid || id, pageId, component, ..._editor });

				return (
					<CatchError key={_uid || id}>
						<Suspense fallback={<div>loading...</div>}>
							<ComponentOutletCtx.Provider
								value={{ id, _uid, component, pageId, index, fieldId, position: p }}
							>
								<ExtComponent
									data-ly={data}
									ly-page={pageId}
									ly-component={component}
									ly-id={_uid || id}
									{...passProps}
									{...props}
								/>
							</ComponentOutletCtx.Provider>
						</Suspense>
						{editMode && fieldId && <ComponentInlet fieldId={fieldId} position={p} />}
						{editMode && AfterEach && <AfterEach component={cmp} index={index} />}
					</CatchError>
				);
			})}
		</>
	);
}

export default ComponentOutlet;
