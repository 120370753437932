import React, { useContext } from 'react';
import Form from 'react-bootstrap/Form';

import get from 'lodash/get';

import { FormContext } from './Form';
import { FormGroupContext } from './FormGroup';

function Feedback({ name, children, ...props }) {
	const formState = useContext(FormContext);
	const groupState = useContext(FormGroupContext);
	const feedback = children || groupState.error || get(formState, `errors.${name}`);

	if (!feedback) return null;

	return (
		<Form.Control.Feedback type="invalid" {...props}>
			{feedback}
		</Form.Control.Feedback>
	);
}

export default Feedback;
