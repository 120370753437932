import { useEffect, useState } from 'react';

function Timer({ children, time = 2000 }) {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setShow(true), time);
		return () => clearTimeout(timeout);
	}, [time]);

	if (!show) return null;

	return children;
}

export default Timer;
