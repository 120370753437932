import React, { useContext } from 'react';
import isEditor from '../utils/isEditor';
import { ComponentOutletCtx } from './ComponentOutlet';

const str = JSON.stringify;

function ComponentInlet({ uid, name, fieldId = 'body', position, as: As, ...props }) {
	const { _uid, pageId, ...ctx } = useContext(ComponentOutletCtx);
	const hasEditor = isEditor();
	if (!hasEditor) return null;

	return (
		<As
			className="outsource-school-add-section"
			{...props}
			ly-id={uid}
			ly-inlet="true"
			data-ly={str({ _uid: uid || _uid, inlet: true, name, fieldId, pageId, position })}
		/>
	);
}

ComponentInlet.defaultProps = { as: 'div' };
export default ComponentInlet;
